
import { computed } from "vue";

export default {
	name: "CoreBlockButton",
	props: {
		settings: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const icon = computed(() => {
			return props.settings?.icon;
		});

		return {
			icon,
		};
	},
};
